import React from 'react'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

const SEO = props => {
  return (
    <StaticQuery
      query={graphql`
        query SEO {
          site {
            siteMetadata {
              defaultImage
              siteUrl
            }
          }
        }
      `}
      render={data => {
        return (
          <Helmet>
            <title>
              {props.title
                ? props.title + ' ⚡ Creandia'
                : 'Creandia Eventbyrå ⚡ Vi skapar upplevelser'}
            </title>
            <meta
              name="description"
              content={
                props.description
                  ? props.description
                  : 'Event- och kommunikationsbyrå i Landskrona. Med lång erfarenhet av evenemang och kommunikation arbetar Creandia för att skapa effektiva, hållbara och tillgängliga evenemang. Creandia producerar bland annat Sveriges största karneval.'
              }
            />
            <meta
              name="description"
              property="og:description"
              content={
                props.description
                  ? props.description
                  : 'Event- och kommunikationsbyrå i Landskrona. Med lång erfarenhet av evenemang och kommunikation arbetar Creandia för att skapa effektiva, hållbara och tillgängliga evenemang. Creandia producerar bland annat Sveriges största karneval.'
              }
            />
            <meta
              name="image"
              content={
                'https://www.creandia.com' + data.site.siteMetadata.defaultImage
              }
            ></meta>
            <meta
              name="image"
              property="og:image"
              content={
                'https://www.creandia.com' + data.site.siteMetadata.defaultImage
              }
            ></meta>
            <html lang="sv" />
          </Helmet>
        )
      }}
    />
  )
}

export default SEO
